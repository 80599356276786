import {useRefinementList} from "react-instantsearch-hooks-web";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import React from "react";

function SimpleRefinementList({attribute, fieldLabel, selectedValues, setSelectedValues}) {
  const {items, refine} = useRefinementList({attribute, limit: 1000});

  const handleChange = (event) => {
    let value = event.target.value;
    if (typeof value === 'string') {
      value = value.split(',');
    }
    setSelectedValues(value);
    items.filter(item => item.isRefined).forEach(item => refine(item.value));
    value.forEach(v => refine(v));
  };

  return (
    <FormControl sx={{m: 1, width: '250px', backgroundColor: '#fff'}} variant="outlined">
      <InputLabel id={`${attribute}-label`} size="small">{fieldLabel}</InputLabel>
      <Select
        labelId={`${attribute}-label`}
        id={attribute}
        multiple
        value={selectedValues}
        label={fieldLabel}
        onChange={handleChange}
        size="small"
        renderValue={(selected) => (selected.length > 1 ? `${selected.length} items` : selected[0])}
      >
        {items.map(item => (
          <MenuItem value={item.value} key={item.value}>
            <Checkbox checked={selectedValues.indexOf(item.value) > -1}/>
            {item.value} ({item.count})
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SimpleRefinementList;