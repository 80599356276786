import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    user: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
}


export const fetchAuthUser = createAsyncThunk('staff/authenticated', async () => {
    try {
        const response = await axios.get('api/staff/authenticated')
        return response.data;
    } catch (err) {
        console.log("Error logging in." + JSON.stringify(err.message));
        return err.message;
    }
})

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUser: (state, action) => {
            state.user = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchAuthUser.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchAuthUser.fulfilled, (state, { payload }) => {               
                    state.user = payload
               
                    if (state.user.id) {
                        state.status = 'succeeded'
                    }
            })
            .addCase(fetchAuthUser.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})
export const getUser = (state) => state.user.user;
export const getUserStatus = (state) => state.user.status;
export const getUserError = (state) => state.user.error;
export const { setUser } = userSlice.actions

export default userSlice.reducer