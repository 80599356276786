import React, {useEffect, useState} from 'react'
import {useSearchParams} from "react-router-dom"
import {useDispatch, useSelector} from 'react-redux'
import algoliasearch from "algoliasearch/lite"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import {makeStyles} from "@material-ui/core/styles";
import "./MenuItems.css";
import Container from '@mui/material/Container'
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';
import {
  Configure,
  Hits,
  InstantSearch,
  Pagination,
  useClearRefinements,
  useHits,
  usePagination
} from "react-instantsearch-hooks-web"

import {unwrapResult} from '@reduxjs/toolkit'
import {createSavedSearch} from '../store/savedSearchesSlice'
import {addMenuItems, createPresentation, updatePresentationMenuItems} from '../store/presentationsSlice'
import {MenuItemHit} from '../components/algolia'
import {FLAG_URL_PREFIX} from '../components/shared/Constants'
import {getUser} from '../store/userSlice'
import HealthChoicesModal from "../components/admin/culinary-concepts/HealthOptionsModal";
import SimpleRefinementList from "../components/SimpleRefinementList";
import BrandRefinementList from "../components/BrandRefinementList";
import ChefRefinementList from "../components/ChefRefinementList";

function CustomPagination(props) {
  const {
    nbHits
  } = usePagination(props);

  return (
    <span>{nbHits}</span>
  );
}

const searchClient = algoliasearch(
  "MTXS7NWQ7T",
  "651fc21e1d1b8598d6be6271229c57c8"
)
const indexName = "dev.conceptlocker.flavoriq.global";
const scrollUpY = 325;
const BrowseConcepts = () => {

  const [searchParams] = useSearchParams();
  const [savedSearchLoaded, setSavedSearchLoaded] = React.useState(false);
  const [country, setCountry] = React.useState([]);
  const [countryList, setCountryList] = React.useState(null);
  const [fromDate, setFromDate] = React.useState(new Date(2023, 0, 1));
  const [toDate, setToDate] = React.useState(new Date());
  const [brandList, setBrandList] = React.useState(null);
  const [chefList, setChefList] = React.useState(null);
  const [, setReadyForRender] = React.useState(false);

  const [query, setQuery] = React.useState('');
  const [createPresentationOpen, setCreatePresentationOpen] = React.useState(false);
  const [createSavedSearchOpen, setCreateSavedSearchOpen] = React.useState(false);
  const createPresentationName = React.useRef(null);
  const createSavedSearchName = React.useRef(null);
  const [snackbar, setSnackbar] = React.useState(null);
  const [presentationList, setPresentationList] = React.useState(null);
  const [presentation, setPresentation] = React.useState('');
  const [presentationMenuItems, setPresentationMenuItems] = React.useState([]);
  const filters = React.useRef({});
  const pageIndex = React.useRef(0);
  const user = useSelector(getUser);
  const presentationsRef = React.useRef(null);
  const saveSearchRef = React.useRef(null);
  const [newPresentationRef, setNewPresentationRef] = React.useState(null);
  const [btnDisabled, setBtnDisabled] = useState(true)
  const dispatch = useDispatch();
  const [numberOfItemsSelected, setNumberOfItemsSelected] = React.useState(0);
  const handleClick = (event) => {
    setNewPresentationRef(newPresentationRef ? null : event.currentTarget);
    setCreatePresentationOpen(true);
  };
  const [savingSearch, setSavingSearch] = React.useState(false);
  const [savingCreatePresentation, setSavingCreatePresentation] = React.useState(false);
  const [healthOptList, setHealthOptList] = useState([]);
  const [productInnoList, setProductInnoList] = useState([]);


  // filters and refinmentstate
  // const [selectedInnovations, setSelectedInnovations] = useState([]);
  const [selectedFeaturedProducts, setSelectedFeaturedProducts] = useState([]);
  const [selectedMealParts, setSelectedMealParts] = useState([]);
  const [selectedHealthChoices, setSelectedHealthChoices] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedChefs, setSelectedChefs] = useState([]);

  function loadPresentationMenuItems(selectedPresentation) {
    if (selectedPresentation === 'create') {
      setPresentation('');
      setPresentationMenuItems([]);
    } else if (Number.isInteger(selectedPresentation)) {
      const data = {
        id: selectedPresentation,
        conceptItems: presentationMenuItems
      };

      dispatch(addMenuItems(data)).then(() => {
          setPresentationMenuItems([]);
          setNumberOfItemsSelected(0);
          setSnackbar({children: 'Presentation Saved successfully', severity: 'success'});
        }
      )
        .catch(rejectedValueOrSerializedError => {
          console.log(rejectedValueOrSerializedError);
          setSnackbar({children: 'Failed to save presentation'});
        });
    }
  }

  function loadPresentations(selectedPresentation) {
    const apiUrl = 'api/presentations/GetUserPresentations/' + user.id
    fetch(apiUrl)
      .then((data) => data.json())
      .then((data) => {
        setPresentationList(data);

        if (selectedPresentation) {
          setPresentation(selectedPresentation);
          setPresentationMenuItems([]);
        }
      })
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const savedSearchId = searchParams.get('search');
        const [
          countriesResponse,
          brandsResponse,
          chefsResponse,
          // productInnovationsResponse,
          healthOptionsResponse,
          savedSearchResponse
        ] = await Promise.all([
          fetch("api/Countries"),
          fetch("api/Brands"),
          fetch("api/Chefs"),
          // fetch("api/ProductInnovations"),
          fetch("api/HealthOptions"),
          savedSearchId ? fetch(`api/savedsearch/GetSavedSearch/${savedSearchId}`) : Promise.resolve(null)
        ]);

        const countries = await countriesResponse.json();
        setCountryList(countries.sort((a, b) => a.countryLongName.localeCompare(b.countryLongName)))

        const brandsData = await brandsResponse.json();
        const brandsDict = brandsData.reduce((acc, item) => {
          acc[item.brandName] = item;
          return acc;
        }, {});
        setBrandList(brandsDict);

        const chefsData = await chefsResponse.json();
        const chefsDict = chefsData.reduce((acc, item) => {
          acc[item.chefName] = item;
          return acc;
        }, {});
        setChefList(chefsDict);

        // const innoData = await productInnovationsResponse.json();
        // setProductInnoList(innoData);

        const healthData = await healthOptionsResponse.json();
        setHealthOptList(healthData);

        // Set up saved search filters if available
        if (savedSearchResponse) {
          const savedSearch = await savedSearchResponse.json();
          if (savedSearch && savedSearch.searchFilters) {
            const savedFilters = JSON.parse(savedSearch.searchFilters);

            setCountry(savedFilters.country || []);
            if (savedFilters.fromDate) setFromDate(new Date(savedFilters.fromDate * 1000));
            if (savedFilters.toDate) setToDate(new Date(savedFilters.toDate * 1000));
            if (savedFilters.query) setQuery(savedFilters.query);

            const applyFilter = (filterKey, value, setState) => {
              if (value) {
                filters.current[filterKey] = value;
                setState(value);
              }
            };

            applyFilter('brand.brandName', savedFilters.brand, setSelectedBrands);
            applyFilter('mealpart', savedFilters.mealpart, setSelectedMealParts);
            applyFilter('segment', savedFilters.segment, setSelectedSegments);
            applyFilter('featuredProducts', savedFilters.featuredProducts, setSelectedFeaturedProducts);
            applyFilter('healthOptions.name', savedFilters.healthOptions, setSelectedHealthChoices);
            applyFilter('chef.chefName', savedFilters.chef, setSelectedChefs);
            // applyFilter('innovations', savedFilters.innovations, setSelectedInnovations);
          }
        }

        setSavedSearchLoaded(true);
        setReadyForRender(true);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setSnackbar({children: 'Failed to load data', severity: 'error'});
        setSavedSearchLoaded(true);
      }
    };

    fetchData();
    loadPresentations(null, '');
  }, [dispatch]);

  useEffect(() => {
    if (numberOfItemsSelected < 0) {
      setNumberOfItemsSelected(0);
    }
    setBtnDisabled(!numberOfItemsSelected > 0);
  }, [numberOfItemsSelected]);
  makeStyles(theme => ({
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    menuPaper: {
      maxHeight: 300
    }
  }));

  function saveSearchOpen() {
    setCreateSavedSearchOpen(true);
  }

  function saveSearchClose() {
    setCreateSavedSearchOpen(false);
  }

  function saveSearch() {
    console.log('saveSearch')
    if (savingSearch) return;

    // Collect filter data based on individual state variables
    const dataFilters = {};
    if (country.length > 0) dataFilters['country'] = country;
    if (fromDate) dataFilters['fromDate'] = parseInt(fromDate.getTime() / 1000);
    if (toDate) dataFilters['toDate'] = parseInt(toDate.getTime() / 1000);
    if (query) dataFilters['query'] = query;
    if (selectedBrands.length > 0) dataFilters['brand'] = selectedBrands;
    if (selectedMealParts.length > 0) dataFilters['mealpart'] = selectedMealParts;
    if (selectedChefs.length > 0) dataFilters['chef'] = selectedChefs;
    if (selectedSegments.length > 0) dataFilters['segment'] = selectedSegments;
    if (selectedFeaturedProducts.length > 0) dataFilters['featuredProducts'] = selectedFeaturedProducts;
    if (selectedHealthChoices.length > 0) dataFilters['healthOptions'] = selectedHealthChoices;
    // if (selectedInnovations.length > 0) dataFilters['innovations'] = selectedInnovations;

    const data = {
      Staff: user.id,
      SearchFilters: JSON.stringify(dataFilters),
      Keywords: query,
      SearchName: createSavedSearchName.current.value
    };

    console.log(data)

    setSavingSearch(true);

    // Dispatch save search action
    dispatch(createSavedSearch(data))
      .then(unwrapResult)
      .then(originalPromiseResult => {
        setCreateSavedSearchOpen(false);
        setSavingSearch(false);
        setSnackbar({children: 'Search saved successfully', severity: 'success'});
      })
      .catch(rejectedValueOrSerializedError => {
        setCreateSavedSearchOpen(false);
        console.log(rejectedValueOrSerializedError);
        setSavingSearch(false);
        setSnackbar({children: 'Failed to save search', severity: 'error'});
      });
  }

  function saveCreatePresentation() {
    if (savingCreatePresentation)
      return;

    var data = {
      Staff: user.id,
      PresentationName: createPresentationName.current.value
    };

    setSavingCreatePresentation(true);

    dispatch(createPresentation(data))
      .then(unwrapResult).then(originalPromiseResult => {
      var data = {
        id: originalPromiseResult.id,
        conceptItems: presentationMenuItems
      };

      dispatch(updatePresentationMenuItems(data)).then(() => {
        setSnackbar({children: 'Presentation Saved successfully', severity: 'success'})
        setNumberOfItemsSelected(0);
        loadPresentations(null, '');
      })
        .catch(rejectedValueOrSerializedError => {
          console.log(rejectedValueOrSerializedError);
          setSnackbar({children: 'Failed to save presentation'});
        });
      setSavingCreatePresentation(false);
      setCreatePresentationOpen(false);
      setSnackbar({children: 'Presentation created successfully', severity: 'success'});
      setPresentationMenuItems([]);

      //loadPresentations(originalPromiseResult.id, originalPromiseResult.presentationName, user.id);
    })
      .catch(rejectedValueOrSerializedError => {
        console.log(rejectedValueOrSerializedError);
        setSavingCreatePresentation(false);
        setCreatePresentationOpen(false);
        setSnackbar({children: 'Failed to create presentation', severity: 'error'});
      });
  }

  function dismissCreatePresentation() {
    setCreatePresentationOpen(false);
  }

  function togglePresentationMenuItems(ids, add) {
    console.log("presentationMenuItems = ", presentationMenuItems)
    if (!presentationMenuItems)
      setPresentationMenuItems(ids);

    var newList = null;
    if (add) {
      var adds = ids.filter(id => !presentationMenuItems.includes(id));
      if (adds.length > 0) {
        newList = presentationMenuItems.concat(adds);
        setNumberOfItemsSelected(numberOfItemsSelected + adds.length);
      }
    } else {

      var removes = ids.filter(id => presentationMenuItems.includes(id));
      if (removes.length > 0) {
        newList = presentationMenuItems.filter(id => !removes.includes(id));
        setNumberOfItemsSelected(numberOfItemsSelected - removes.length);
      }

    }

    if (newList) {
      setPresentationMenuItems(newList);
    }
    if (numberOfItemsSelected < 0) {
      setNumberOfItemsSelected(0);
    }
  }

  function CustomClearRefinements(props) {
    const {refine} = useClearRefinements(props);

    const [didMount, setDidMount] = useState(false);
    useEffect(() => {
      setDidMount(true);
      return () => setDidMount(false);
    }, [])

    if (!didMount) {
      return null;
    }

    return (
      <FormControl sx={{m: 1, width: '135px'}}>
        <Button
          variant="contained"
          sx={{color: "#fff"}}
          color="primary"
          disableElevation
          onClick={() => {
            // filters.current = {};
            refine();
            setCountry([]);
            // setSelectedInnovations([]);
            setSelectedFeaturedProducts([]);
            setSelectedMealParts([]);
            setSelectedHealthChoices([]);
            setSelectedSegments([]);
            setSelectedBrands([]);
            setSelectedChefs([]);
            setFromDate(new Date(2023, 0, 1));
            setToDate(new Date());
          }}
        >
          Clear Filters
        </Button>
      </FormControl>
    );
  }

  function SelectAllHits(props) {
    const {hits, results, sendEvent} = useHits(props);
    //  console.log("SelectAllHits hits " + JSON.stringify(hits));
    let selectAllIds = [];
    let excludedCount = 0;
    if (presentationMenuItems) {
      selectAllIds = hits.map(hit => hit.id);
      excludedCount = selectAllIds.filter(id => !presentationMenuItems.includes(id)).length;
    }

    const [didMount, setDidMount] = useState(false);
    useEffect(() => {
      setDidMount(true);
      return () => setDidMount(false);
    }, [])

    if (!didMount) {
      return null;
    }

    return (
      <FormControlLabel
        className="checkbox-label"
        control={<Checkbox
          checked={presentationMenuItems && excludedCount === 0}
          onChange={(event) => togglePresentationMenuItems(selectAllIds, event.target.checked)}
        />}
        label="Select All For Presentation"
        title={presentationMenuItems ? '' : 'Please select or create a presentation to get started'}
      />
    );
  }

  // Loading...
  if (!(savedSearchLoaded && countryList && brandList && presentationList))
    return null;

  let searchFilters = [];
  let refinementList = {};

  // Country
  if (country) {
    let vs = country.map(v => 'country.countryName:"' + v + '"');
    if (vs.length > 0)
      searchFilters.push('(' + vs.join(' OR ') + ')');
  }

  // Date range
  var fromDateMonth = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
  var toDateMonth = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 1);
  searchFilters.push('date_timestamp >= ' + (fromDateMonth.getTime() / 1000));
  searchFilters.push('date_timestamp < ' + (toDateMonth.getTime() / 1000));

  // Refinements
  for (var key in filters.current) {
    let r = filters.current[key];
    if (r && r.length > 0)
      refinementList[key] = r;
  }

  // UI State
  let initialUiState = {
    [indexName]: {
      refinementList: refinementList,
      page: (pageIndex.current || 0)
    }
  };

  return (
    <>
      {createPresentationOpen ? (
        <ClickAwayListener onClickAway={dismissCreatePresentation}>
          <Popper open={createPresentationOpen} anchorEl={presentationsRef.current} placement='bottom-end' transition>
            {({TransitionProps}) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper sx={{position: 'absolute', left: '-40vw', top: '22vh'}}>
                  <Box sx={{backgroundColor: '#f7f7f7', display: 'flex', flexDirection: 'column', p: 1,}}>
                    <Typography sx={{p: 2}}><em>Get Started With Your Presentation</em></Typography>
                    <FormControl sx={{m: 1, width: '300px', backgroundColor: '#fff'}} variant="outlined">
                      <TextField
                        label="Name Your Presentation"
                        fullWidth
                        id="create-presentation-input"
                        size="small"
                        placeholder="i.e. McDonald's Deck"
                        inputRef={createPresentationName}
                        InputLabelProps={{shrink: true}}
                      />
                    </FormControl>
                    <FormControl sx={{m: 1, width: '150px'}}>
                      <LoadingButton
                        variant="contained"
                        sx={{color: '#fff'}}
                        color="primary"
                        disableElevation
                        loading={savingCreatePresentation}
                        onClick={saveCreatePresentation}
                      >
                        Create Presentation
                      </LoadingButton>
                    </FormControl>
                  </Box>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      ) : null}

      {createSavedSearchOpen ? (
        <ClickAwayListener onClickAway={saveSearchClose}>
          <Popper style={{zIndex: 10000}} open={createSavedSearchOpen} anchorEl={presentationsRef.current}
                  placement='top-end' transition>
            {({TransitionProps}) => (
              <Fade {...TransitionProps} timeout={350}>
                <Paper>
                  <Box sx={{
                    backgroundColor: '#f7f7f7',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 1,
                    marginTop: '160px'
                  }}>
                    <Typography sx={{p: 2}}><em>Give your search a name.</em></Typography>
                    <FormControl sx={{m: 1, width: '300px', backgroundColor: '#fff'}} variant="outlined">
                      <TextField
                        label="Name Your Search"
                        fullWidth
                        id="create-presentation-input"
                        size="small"
                        defaultValue={query}
                        required
                        inputRef={createSavedSearchName}
                        InputLabelProps={{shrink: true}}
                      />
                    </FormControl>
                    <FormControl sx={{m: 1, width: '100px'}}>
                      <LoadingButton
                        size="small"
                        variant="contained"
                        sx={{color: '#fff'}}
                        color="primary"
                        disableElevation
                        loading={savingCreatePresentation}
                        onClick={() => {
                          if (createSavedSearchName && createSavedSearchName.current.value !== '') {
                            saveSearch();
                          }
                        }}
                      >
                        Save Search
                      </LoadingButton>
                    </FormControl>
                  </Box>
                </Paper>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      ) : null}

      <Container
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 2,
          mb: 2
        }} fixed>

        <FormControl sx={{m: 1}} variant="outlined">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month']}
              label="Creation Date From"
              value={fromDate}
              minDate={new Date('2023-01-02')}
              maxDate={new Date()}
              onChange={(newFromDate) => {
                setFromDate(newFromDate);
              }}
              openTo='month'
              disableCloseOnSelect={false}
              renderInput={(params) =>
                <TextField {...params} className="date-picker" helperText={null} name="fromDate" size="small"/>}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{m: 1}} variant="outlined">
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={['year', 'month']}
              label="Creation Date To"
              openTo='month'
              value={toDate}
              minDate={new Date('2023-01-02')}
              maxDate={new Date()}
              disableCloseOnSelect={false}
              onChange={(newToDate) => {
                setToDate(newToDate);
              }}
              renderInput={(params) => <TextField {...params} className="date-picker" helperText={null} name="toDate"
                                                  size="small"/>}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{width: '75%'}} variant="outlined" ref={presentationsRef}>
          <TextField
            label="Keyword Search"
            type="search"
            fullWidth
            id="search-input"
            size="small"
            placeholder='Word (spicy), phrase ("chicken pizza"), or exclusion (-coffee)'
            InputLabelProps={{shrink: true}}
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="end"><SearchIcon className='search-icon'/></InputAdornment>,
            }}
          />
        </FormControl>
      </Container>

      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        routing={false}
        initialUiState={initialUiState}
        onStateChange={({uiState, setUiState}) => {
          // Scroll up
          setTimeout(() => {
            if (window.scrollY > scrollUpY)
              window.scrollTo(0, scrollUpY);
          }, 1);

          if (uiState && uiState[indexName])
            pageIndex.current = uiState[indexName].page || 0;

          setUiState(uiState);
        }}
      >
        <Configure
          advancedSyntax="true"
          query={query}
          filters={searchFilters.join(' AND ')}/>

        <Container maxWidth="lg" sx={{
          display: 'flex',
          flexWrap: 'wrap',
          mt: 2,
          mb: 0,
          backgroundColor: '#f7f7f7',
          pt: 2,
          pb: 2,
          maxWidth: '1190px',
          flexDirection: "column",

        }} fixed>
          <Box sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
            <Typography gutterBottom variant="body2" component="div" sx={{mt: 1, mr: 1}}>
              <em>Refine</em>
            </Typography>
            <FormControl sx={{m: 1, width: '250px', backgroundColor: 'white'}} variant="outlined">
              <InputLabel id="country-label" size="small">Region/Country</InputLabel>
              <Select
                labelId="country-label"
                id="country"
                multiple
                value={country}
                label="Region/Country"
                onChange={(event) => {
                  let {
                    target: {value},
                  } = event;
                  // On autofill we get a stringified value.
                  if (typeof value === 'string')
                    value = value.split(',');
                  filters.current = {};
                  setCountry(value);
                }}
                size="small"
                fullWidth
                className="country-select"
                renderValue={(selected) => ((selected.length > 1) ? (selected.length + ' items') : selected[0])}
              >
                {countryList.map(countryItem => (
                  <MenuItem value={countryItem.countryLongName} key={countryItem.countryLongName}>
                    <Checkbox sx={{p: '5px'}} checked={country.indexOf(countryItem.countryLongName) > -1}/>
                    <img className="select-icon"
                         src={countryItem.countryFlagImage !== '' && countryItem.countryFlagImage !== null
                           ? FLAG_URL_PREFIX +
                           countryItem.countryFlagImage : "/images/blank.png"}/>{" "}
                    {countryItem.countryLongName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ChefRefinementList
              attribute="chef.chefName"
              fieldLabel="Chef"
              selectedValues={selectedChefs}
              setSelectedValues={setSelectedChefs}
              chefList={chefList}
            />
            <SimpleRefinementList
              attribute="segment.name"
              fieldLabel="Segment"
              selectedValues={selectedSegments}
              setSelectedValues={setSelectedSegments}
            />
            <BrandRefinementList
              attribute="brand.brandName"
              fieldLabel="Brands"
              selectedValues={selectedBrands}
              setSelectedValues={setSelectedBrands}
              brandList={brandList}
            />
          </Box>
          <Box sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
            <Typography gutterBottom variant="body2" component="div" sx={{mt: 1, mr: 1}}/>
            <HealthChoicesModal healthOptions={healthOptList}/>
            <SimpleRefinementList
              attribute="healthOptions.name"
              fieldLabel="Health Choices"
              selectedValues={selectedHealthChoices}
              setSelectedValues={setSelectedHealthChoices}
            />
            <SimpleRefinementList
              attribute="mealpart"
              fieldLabel="Meal Part"
              selectedValues={selectedMealParts}
              setSelectedValues={setSelectedMealParts}
            />
            <SimpleRefinementList
              attribute="featuredProducts"
              fieldLabel="Featured Category"
              selectedValues={selectedFeaturedProducts}
              setSelectedValues={setSelectedFeaturedProducts}
            />
            {/*<SimpleRefinementList*/}
            {/*  attribute="innovations"*/}
            {/*  fieldLabel="Innovations"*/}
            {/*  selectedValues={selectedInnovations}*/}
            {/*  setSelectedValues={setSelectedInnovations}*/}
            {/*/>*/}
            {/*<ProductInnovationsModal productInnovations={productInnoList}/>*/}
          </Box>
        </Container>
        <Container maxWidth="lg" sx={{
          display: 'flex',
          flexWrap: 'wrap',
          mt: 0,
          mb: 2,
          backgroundColor: '#fff',
          pt: 0,
          pb: 0,
          maxWidth: '1190px',
          flexDirection: "column",

        }} fixed className="filters-bar">
          <Box sx={{
            maxWidth: '300px',
            display: 'flex',
            alignItems: 'flex-start',
            alignSelf: 'flex-end',
            marginLeft: '50px'
          }}>
            <CustomClearRefinements/>
            <LoadingButton
              sx={{m: 1, width: '135px', color: "#fff"}}
              variant="contained"
              color="primary"
              disableElevation
              ref={saveSearchRef}
              loading={savingSearch}
              onClick={saveSearchOpen}
            >
              Save Search
            </LoadingButton>
          </Box>

        </Container>

        <Container
          sx={{
            maxWidth: "1340px",
            display: 'flex',
            flexWrap: 'nowrap',
            mt: 2,
            mb: 2,
            justifyContent: 'space-between',
            backgroundColor: '#f7f7f7',
            pt: 1,
            pb: 1
          }} fixed>
          <Typography gutterBottom variant="body2" component="div" sx={{mt: 1, mr: 1}}>
            <em>Search Results:</em> <CustomPagination/>
          </Typography>

        </Container>

        <Container
          sx={{
            maxWidth: "1340px",
            display: 'flex',
            flexWrap: 'nowrap',
            mt: 2,
            mb: 2,
            justifyContent: 'space-between',

          }} fixed>

          <SelectAllHits/>
          <Typography variant="body3" sx={{
            pr: 1,
            pl: 1,
            ml: '10px',
            alignItems: 'center',
            width: '30%',
            alignSelf: 'center',
            justifyContent: 'start',
            left: '0px'
          }}>
            {(numberOfItemsSelected == 0) ? 'Select the items you want to add to a new or existing presentiation.' : numberOfItemsSelected + ' Items Selected'}
          </Typography>
          <Box sx={{
            alignItems: 'center',
            display: 'flex',
          }}>

            <Button
              variant="contained"
              sx={{color: '#fff'}}
              color="primary"
              disableElevation
              disabled={btnDisabled}
              ref={newPresentationRef}
              onClick={handleClick}
            >
              + Create a New Presentation
            </Button>
            <Typography variant="body2" sx={{pl: 1, pr: 1}}>or</Typography>
            <FormControl sx={{width: '255px'}} variant="outlined">
              <TextField
                select
                disabled={btnDisabled}
                id="presentations"
                value={presentation}
                label="Select a Presentation"
                onChange={(event) => loadPresentationMenuItems(event.target.value)}
                size="small"
                fullWidth
                className="select"
              >

                {presentationList.map(presentation => (
                  <MenuItem value={presentation.id} key={presentation.id}>
                    {presentation.presentationName}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

          </Box>
        </Container>

        <Container
          sx={{
            maxWidth: "1340px",
            display: 'flex',
            flexDirection: 'column',
            mt: 2,
            pb: 4,
          }} fixed>
          <Hits hitComponent={({hit}) => <MenuItemHit hit={hit} presentationMenuItems={presentationMenuItems}
                                                      togglePresentationMenuItems={togglePresentationMenuItems}/>}/>
          <Pagination/>
        </Container>

      </InstantSearch>

      {!!snackbar && (
        <Snackbar open onClose={() => setSnackbar(null)} autoHideDuration={5000}>
          <Alert {...snackbar} onClose={() => setSnackbar(null)}/>
        </Snackbar>
      )}
    </>
  );
}
export default BrowseConcepts