import React from 'react'
import Button from '@mui/material/Button'
import './PrimaryNavTabBar.css'
import { Box } from "@mui/material"
import { NavLink } from 'react-router-dom'
import logo from '../../assets/images/griffith-logo.png';

export default function PrimaryNavTabBar() {
 
  const styles = {
    navButton: {
      'fontWeight': 500,
      'fontSize': 16,
      'fontStyle': 'italic',
      'textTransform': 'capitalize',
      'color': '#ffffff',
      'borderRadius': '0px', 
      'borderTopLeftRadius': '4px',
      'borderTopRightRadius': '4px',
      'width': '214px',
      'marginRight': '10px',
      'backgroundColor': '#a0c800',
        '&.active': {
          'backgroundColor': '#ff9300',
        },
        '&.hover': {
          'backgroundColor': '#a0c800 !important',
        },
        '&:focus': {
          boxShadow: 'none)',
        },
      }
    }
  

  return (
    <Box className="header">

      <nav>
        <a href="/"><img src={logo} alt="Griffith Foods" className='hero--logo' /></a>
        <div className="nav--grouped">        

          <Button component={NavLink} variant="contained" sx={styles.navButton} color="secondary" to="/concepts" disableElevation
           >
            Browse Concepts

          </Button>
          <Button component={NavLink} variant="contained" sx={styles.navButton}  color="secondary" to="/presentations" disableElevation
            >
            My Presentations
          </Button>
          <Button component={NavLink} variant="contained" sx={styles.navButton}  color="secondary" to="/saved-searches" disableElevation
            >
            Saved Searches
          </Button>
        </div>
        {/*<Button component={NavLink} variant="contained" sx={styles.navButton} to="/admin/manage-menu-filters" className="nav--admin" disableElevation
        >
          Admin Access
  </Button>*/}
      </nav>
    </Box>
  )
}





