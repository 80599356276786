import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    countries: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    selectedCountry: '',
}

export const fetchCountries = createAsyncThunk('countries/fetchCountries', async () => {
    try {
        const response = await axios.get('api/countries')      
        
        response.data.sort((a, b) => (a.countryLongName > b.countryLongName) ? 1 : -1)
        return [...response.data];
    } catch (err) {
        return err.message;
    }
})

const countriesSlice = createSlice({
    name: "countries",
    initialState,
    reducers: {
        setList: (state, { payload }) => {
            state.countries = payload;

        },
        setSelectedCountry: (state, { payload }) => {
            state.selectedCountry = payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCountries.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchCountries.fulfilled, (state, { payload }) => {
                state.status = 'succeeded'
                state.selectedCountry = ''
                state.countries = payload
            })
            .addCase(fetchCountries.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })

    }
})

export const selectAllCountries = (state) => state.countries.countries;
export const getCountriesStatus = (state) => state.countries.status;
export const getCountriesError = (state) => state.countries.error;
export const getSelectedCountry = (state) => state.countries.countries.find(ln => ln.countryCode === state.countries.selectedCountry)

export const { setList, setSelectedCountry } = countriesSlice.actions

export default countriesSlice.reducer