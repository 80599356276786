import React from 'react';
import {useRefinementList} from 'react-instantsearch-hooks-web';
import {FormControl, InputLabel, Select, MenuItem, Checkbox} from '@mui/material';
import {CHEF_PHOTOS_URL_PREFIX} from './shared/Constants';

function ChefRefinementList({attribute, fieldLabel, selectedValues, setSelectedValues, chefList}) {
  const {items, refine} = useRefinementList({attribute, limit: 1000});

  return (
    <FormControl sx={{m: 1, width: '250px', backgroundColor: '#fff'}} variant="outlined">
      <InputLabel id={`${attribute}-label`} size="small">{fieldLabel}</InputLabel>
      <Select
        labelId={`${attribute}-label`}
        id={attribute}
        multiple
        value={selectedValues}
        label={fieldLabel}
        onChange={(event) => {
          const {value} = event.target;
          const selected = typeof value === 'string' ? value.split(',') : value;

          items.forEach(item => {
            const isSelected = selected.includes(item.value);
            if (item.isRefined !== isSelected) {
              refine(item.value);
            }
          });
          setSelectedValues(selected);
        }}
        size="small"
        renderValue={(selected) => (selected.length > 1 ? `${selected.length} items` : selected[0])}
      >
        {items.map(item => {
          const isRefined = selectedValues.includes(item.value);
          const chefData = chefList[item.value] || {};
          const chefPhoto = chefData.chefPhoto ? `${CHEF_PHOTOS_URL_PREFIX}${chefData.chefPhoto}` : "/images/blank.png";

          return (
            <MenuItem value={item.value} key={item.value}>
              <Checkbox sx={{p: '5px'}} checked={isRefined}/>
              <img
                className="select-icon"
                src={chefPhoto}
                alt={item.value}
                style={{width: 20, height: 20, marginRight: 8}}
              />
              {` ${item.value} (${item.count})`}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default ChefRefinementList;
