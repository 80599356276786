
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    brands: [],
    brandOptions: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    selectedBrand: '',
}

export const fetchBrands = createAsyncThunk('brands/fetchBrands', async () => {
    try {
        const response = await axios.get('api/brands')      
        response.data.sort((a, b) => (a.brandName > b.brandName) ? 1 : -1)      
        return [...response.data];
    } catch (err) {
        return err.message;
    }
})

const brandsSlice = createSlice({
    name: "brands",
    initialState,
    reducers: {
        setList: (state, { payload }) => {
            state.brands = payload;
        },
        setBrandOptions: (state, { payload }) => {
             state.selectedBrand = payload
         },
        setSelectedBrand: (state, { payload }) => {
             state.selectedBrand = payload
         },
         getBrandByName: (state, { payload }) => {
            state.brands.find(d => d.brandName === payload.brandName)
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchBrands.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchBrands.fulfilled, (state, { payload }) => {
                state.status = 'succeeded'
                state.selectedBrand = ''
                state.brands = payload
               
                state.brands.map((brand) => {
                    state.brandOptions.push(brand.brandName);                    
                });           
            })
            .addCase(fetchBrands.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllBrands = (state) => state.brands.brands;
export const getBrandsStatus = (state) => state.brands.status;
export const getBrandsError = (state) => state.brands.error;
export const getSelectedBrand = (state) => state.brands.selectedBrand;
export const getBrandOptions = (state) => state.brands.brandOptions;

export const { setList, setSelectedBrand, getBrandByName } = brandsSlice.actions

export default brandsSlice.reducer