import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { getUser } from '../../store/userSlice';

export function MenuItemHit({ hit, presentationMenuItems, togglePresentationMenuItems }) {
    const checked = presentationMenuItems && presentationMenuItems.includes(hit.id);
    const launchDate = new Date(hit.date_timestamp * 1000);
    const user = useSelector(getUser);

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#fafafa'),
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: '#fff',
        },
        padding: 0,
    }));

    const healthOptions = hit?.healthOptions?.map((x) => x.name).join(' | ') || [];

    return (
      <Box
        sx={{
            alignItems: 'center',
            display: 'flex',
            borderTop: '2px solid #afafaf',
            pt: 2,
            pb: 2,
        }}
        className="menu-item-row"
        key={hit.id}
      >
          <FormControlLabel
            className="checkbox-label"
            sx={{ mr: 0 }}
            control={<Checkbox />}
            label=""
            title=""
            checked={checked}
            onChange={(event) => togglePresentationMenuItems([hit.id], event.target.checked)}
            aria-describedby={hit.id}
          />

          <img className="menu-item-row--menu-thumbnail" src={hit.blobImageName} />

          <Box
            className="menu-item-row--details"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
          >
              <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
              >
                  <Typography
                    gutterBottom
                    variant="h4"
                    component="div"
                    sx={{ fontSize: '1.8rem', mt: 1, mr: 1, mb: 0 }}
                  >
                      <em>{hit.conceptName}</em>
                  </Typography>
                  <Box sx={{ width: '55px' }}>
                      <ColorButton
                        startIcon={<VisibilityIcon />}
                        variant="body2"
                        href={`/api/presentations/previewpresentationconcept/export/${hit.id}`}
                      ></ColorButton>
                      {user.accessTier === 2 && (
                        <ColorButton
                          startIcon={<ModeEditOutlineIcon />}
                          variant="body2"
                          href={`/admin/edit-concept/${hit.id}`}
                        ></ColorButton>
                      )}
                  </Box>
              </Box>
              <Box
                sx={{
                    flexDirection: 'row',
                    display: 'flex',
                }}
              >
                  <Typography
                    gutterBottom
                    variant="body2"
                    component="div"
                    sx={{ mt: 1, mr: 1 }}
                  >
                      <b>Chef:</b> &nbsp;
                      {hit.chef.chefPhoto && (
                        <img
                          className="menu-item-row--brand-thumbnail"
                          src={hit.chef.chefPhoto}
                          style={{ marginBottom: '-10px' }}
                        />
                      )}
                      &nbsp;&nbsp;{hit.chef.chefName}
                      {hit.segment && (
                        <>
                            &nbsp;&nbsp; | &nbsp;&nbsp;<b>Segment:</b> {hit.segment.name}
                        </>
                      )}
                      &nbsp;&nbsp; | &nbsp;&nbsp;<b>Brand:</b>
                      {hit.brand.brandLogo && (
                        <img
                          className="menu-item-row--brand-thumbnail"
                          src={hit.brand.brandLogo}
                          style={{ marginBottom: '-10px' }}
                        />
                      )}
                      &nbsp;{hit.brand.brandName.length > 0 ? hit.brand.brandName : hit.brandOtherText }
                      &nbsp;&nbsp; | &nbsp;&nbsp;<b>Country/Region:</b>&nbsp;&nbsp;
                      {hit.country.countryFlagIcon && (
                        <img
                          className="menu-item-row--flag-thumbnail"
                          src={hit.country.countryFlagIcon}
                          style={{ marginBottom: '-10px' }}
                        />
                      )}
                      {hit.country.countryName}
                      <br />
                      <b>Creation Date:</b> {format(launchDate, 'MMMM')} {format(launchDate, 'yyyy')}
                      &nbsp;&nbsp; | &nbsp;&nbsp;<b>Meal Part:</b> {hit.mealpart}
                  </Typography>
              </Box>

              <Typography
                gutterBottom
                variant="body2"
                component="div"
                sx={{ mt: 1, mr: 1 }}
              >
                  {hit.description}
              </Typography>

              {healthOptions.length > 0 && (
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  sx={{ mt: 1, mr: 1 }}
                >
                    <b>Health Choices:</b> {healthOptions}
                </Typography>
              )}
          </Box>
      </Box>
    );
}

MenuItemHit.propTypes = {
    hit: PropTypes.object.isRequired,
    presentationMenuItems: PropTypes.array.isRequired,
    togglePresentationMenuItems: PropTypes.func.isRequired,
};
