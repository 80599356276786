
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    mealparts: [],
    mealpartOptions: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    selectedMealpart: '',
}

export const fetchMealparts = createAsyncThunk('mealparts/fetchMealparts', async () => {
    try {
        const response = await axios.get('api/mealparts')      
        response.data.sort((a, b) => (a.mealPartName > b.mealPartName) ? 1 : -1)      
        return [...response.data];
    } catch (err) {
        return err.message;
    }
})

const mealpartsSlice = createSlice({
    name: "mealparts",
    initialState,
    reducers: {
        setList: (state, { payload }) => {
            state.mealpart = payload;
        },
        setMealpartOptions: (state, { payload }) => {
             state.selectedMealpart = payload
         },
        setSelectedMealpart: (state, { payload }) => {
             state.selectedMealpart = payload
         },
         getMealpartByName: (state, { payload }) => {
            state.mealparts.find(d => d.mealPartName === payload.mealPartName)
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMealparts.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchMealparts.fulfilled, (state, { payload }) => {
                state.status = 'succeeded'
                state.selectedMealpart = ''
                state.mealparts = payload
               
                state.mealparts.map((mealpart) => {
                    state.mealpartOptions.push(mealpart.mealPartName);                    
                });
              
            })
            .addCase(fetchMealparts.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectAllMealparts = (state) => state.mealparts.mealparts;
export const getMealpartsStatus = (state) => state.mealparts.status;
export const getMealpartsError = (state) => state.mealparts.error;
export const getSelectedMealpart = (state) => state.mealparts.selectedMealpart;
export const getMealPartOptions = (state) => state.mealparts.mealpartOptions;

export const { setList, setSelectedMealpart, getMealpartByName } = mealpartsSlice.actions

export default mealpartsSlice.reducer