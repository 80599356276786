import { BlobServiceClient} from '@azure/storage-blob';

//const sasToken = "sp=racwdlm&st=2022-12-07T17:02:40Z&se=2033-12-08T01:02:40Z&sip=172.111.201.107&spr=https&sv=2021-06-08&sr=c&sig=LI5O0VPuLFUWTi1P1aXuGQ5HYQ8Te83ZUUtbHEwqMPc%3D"; // Fill string with your SAS token
const sasToken = "sp=racwlm&st=2022-01-30T19:30:11Z&se=2033-01-31T03:30:11Z&spr=https&sv=2021-06-08&sr=c&sig=w0THblmXQOUgkFUOSMtba1TMQDOqwyKjzVBRbPxtqsU%3D"; // Fill string with your SAS token

const containerName = `concept-locker`;
const storageAccountName = "flavoriqblobstorage"; 


// disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return !((!storageAccountName || !sasToken));
};

// return list of blobs in container to display
const getBlobsInContainer = async (containerClient) => {
  const returnedBlobUrls = [];

  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    returnedBlobUrls.push(
      `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
    );
  }

  return returnedBlobUrls;
};


const createBlobInContainer = async (containerClient, folder, file) => {

  // create blobClient for container
  const blobClient = containerClient.getBlockBlobClient(folder + file.name);
console.log("blobClient " + folder + file.name)
  // set mimetype as determined from browser with file upload control
  const options = { blobHTTPHeaders: { blobContentType: file.type } };

  // upload file
  await blobClient.uploadBrowserData(file, options);
 // await blobClient.setMetadata({UserName : 'shubham'});
};

const uploadFileToBlob = async (folder, file) => {
  if (!file) return [];

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  );
  // get Container - full public read access
  const containerClient = blobService.getContainerClient(containerName);

  // upload file
  await createBlobInContainer(containerClient, folder, file);

  // get list of blobs in container
 // return getBlobsInContainer(containerClient);
 return;
};

export default uploadFileToBlob;