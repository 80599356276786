import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { v4 as uuid } from 'uuid';
import { arrayMoveMutable } from 'array-move';
import { useDispatch } from 'react-redux'

const initialState = {
   report: null,
   csvFileName: "",
   headerRow: [],
   menuItems: [],
   daypartsFiltered: [],
   mealpartsFiltered: [],
   brandsFiltered: [],
   reportForImport: [],
   adminUser: 0,
   error: null,
   status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'

   dbHeaders: [
      {
         id: "DayPart",
         dbField: 'Day Part',
         mappedCSVField: '--------- Select ---------'
      },
      {
         id: "MealPart",
         dbField: 'Meal Part',
         mappedCSVField: '--------- Select ---------'
      },
      {
         id: "Brand",
         dbField: 'Company',
         mappedCSVField: '--------- Select ---------'
      },
      {
         id: "MenuItemName",
         dbField: 'Menu Item Name',
         mappedCSVField: '--------- Select ---------'
      },
      {
         id: "Description",
         dbField: 'Menu Item Description',
         mappedCSVField: '--------- Select ---------'
      },
      {
         id: "OriginalImageURL",
         dbField: 'Link',
         mappedCSVField: '--------- Select ---------'
      },
      {
         id: "BlobImageName",
         dbField: 'Image File',
         mappedCSVField: '--------- Select ---------'
      },
      {
         id: "UseDefaultImage",
         dbField: 'Default Image',
         mappedCSVField: '--------- Select ---------'
      },

   ],
}

const moveMenuItem = (state, DBHeaderId) => {
   const dbHeaderIndex = state.dbHeaders.findIndex(header => header.id === DBHeaderId);
   const headerIndex = state.headerRow.findIndex(header => header === state.dbHeaders[dbHeaderIndex].mappedCSVField);
   state.menuItems.map((item) => {
      if (item.items && headerIndex !== -1) {
         arrayMoveMutable(item.items, headerIndex, dbHeaderIndex);
      }
   });
}

const mapOptionalField = (state, DBHeaderId, CSVField) => {
   const dbHeaderIndex = state.dbHeaders.findIndex(header => header.id === DBHeaderId);
   const header = state.headerRow.find(h => h === CSVField);
   const headerIndex = state.headerRow.findIndex(header => header === state.dbHeaders[dbHeaderIndex].mappedCSVField);
   if (headerIndex === -1) {
      if (header === undefined) {
        state.headerRow.splice(dbHeaderIndex, 0, CSVField);
         state.menuItems.map((item) => {
            if (item.items)
               item.items.splice(dbHeaderIndex, 0, "");
         });
      }
      state.dbHeaders[dbHeaderIndex].mappedCSVField = CSVField
   }
}

export const filterMenuItemCategories = (state) => {
   const dpts = state.menuItems.map(item => item.dayPartName)
   const daypartsFiltered = dpts.filter((q, idx) => dpts.indexOf(q) === idx);
   const mealparts = state.menuItems.map(item => item.mealPartName)
   const mealpartsFiltered = mealparts.filter((q, idx) => mealparts.indexOf(q) === idx);
   const brands = state.menuItems.map(item => item.brandName)
   const brandsFiltered = brands.filter((q, idx) => brands.indexOf(q) === idx);

   let temparr = [];
   let tempfields = {};

   daypartsFiltered.map((item) => {
      tempfields = {
         id: uuid(),
         dayPartName: item,
         mappedCSVName: '--------- Select ---------',
      }
      temparr.push(tempfields);
   })
   state.daypartsFiltered = temparr;

   temparr = []
   mealpartsFiltered.map((item) => {
      tempfields = {
         id: uuid(),
         mealPartName: item,
         mappedCSVName: '--------- Select ---------',
      }
      temparr.push(tempfields);
   })
   state.mealpartsFiltered = temparr;

   temparr = []
   brandsFiltered.map((item) => {
      tempfields = {
         id: uuid(),
         brandName: item,
         mappedCSVName: '--------- Select ---------',
      }
      temparr.push(tempfields);
   })
   state.brandsFiltered = temparr;
}

export const createMenuGroup = createAsyncThunk('csvReport/createMenuGroup', async (data, { rejectWithValue })  => {
   try {

      const headers = {
         "Accept": "application/json",
          "Content-Type": "application/json",
      };

      const response = await axios.post('api/menugroups', JSON.stringify(data),
      {headers})
      
      return response.data;
   } catch (err) {
      console.log("err: " + JSON.stringify(err.message))
      return rejectWithValue([], err);
   }})




const csvReportSlice = createSlice({
   name: "csvReport",
   initialState,
   reducers: {
      importReport: (state, { payload }) => {
         //form data for EF 
         const menuImports = [
            {
               "adminUser": state.adminUser,
               "cSVFileName": state.csvFileName,
               "statusCode": "Processing",
               "errorCount": 0,
               "recordsCount": 0
            }
         ]

         let temparr = [];
         let tempfields = {};

         state.menuItems.map((item) => {
            tempfields = {
               mealPart: item.mealPartId,
               dayPart: item.dayPartId,
               brand: item.brandId,
               menuItemName: item.menuItemName,
               description: item.description,
               originalImageURL: "'" + item.originalImageUrl + "'",  
               blobImageName: "'" + item.blobImageName + "'",  
               useDefaultImage: "'" + item.useDefaultImage + "'"          
            }
            temparr.push(tempfields);
         })

         state.reportForImport.push({
            "country": payload.selectedcountry.countryCode,
            "dateForRange": payload.reportDate,
            "menuImports": menuImports,
            "menuItems": temparr
         })
        
      },
      addReport: (state, { payload }) => {
         state.csvFileName = payload.file;

         //strip out empty rows         
         let p = payload.results.data.filter(o => !Object.keys(o).every(k => !o[k]));

         // full csv report
         state.report = p;

         // first row of csv is the header for mapping
         // if first row (header) contains empty cells strip out those columns. 
         let firstRow = p[0];
         state.headerRow = firstRow.filter(e => String(e).trim());

         // find matches
         state.headerRow.map((header) => {
            const dbHeader = state.dbHeaders.find(dbheader => dbheader.dbField === header);
            if (dbHeader !== undefined) {
               dbHeader.mappedCSVField = header;
             //  console.log("dbHeader.mappedCSVField: " + JSON.stringify(dbHeader.mappedCSVField))
            }
         })

         // menu items for mapping
         state.menuItems = p.map(items => ({ items }));
         state.menuItems.splice(0, 1);

         // clean empty arrays and array less than required 5 fields
         const output = [].concat(state.menuItems.filter(r => r.items.items !== null && r.items.length >= 5));
         state.menuItems = output;
      },
      mapDbHeaders: (state, { payload }) => {
         const dbHeader = state.dbHeaders.find(header => header.id === payload.id);
         if (dbHeader !== -1) {
            dbHeader.mappedCSVField = payload.mappedCSVField;
         }
      },
      mapMenuItems: (state, { payload }) => {
         //get columns in the right order to match up with db columns     
         moveMenuItem(state, "DayPart")
         moveMenuItem(state, "MealPart")
         moveMenuItem(state, "MenuItemName")
         moveMenuItem(state, "Description")

         //optional fields need to be mapped before moving if they don't exist in csv 
         mapOptionalField(state, "OriginalImageURL", "Link")
         moveMenuItem(state, "OriginalImageURL")
         mapOptionalField(state, "BlobImageName", "Image File")
         moveMenuItem(state, "BlobImageName")
         mapOptionalField(state, "UseDefaultImage", "Default Image")
         moveMenuItem(state, "UseDefaultImage")
         

         //remove any extra csv columns 
         state.menuItems.map((item) => {
            if (item.items)
               item.items.splice(8)
         });

         // reformat menuitems with header field names add generated uids
         let temparr = [];
         let tempfields = {};

         state.menuItems.map((item) => {
            tempfields = {
               id: uuid(),
               dayPartId: 0,
               dayPartName: item.items[0],
               mealPartId: 0,
               mealPartName: item.items[1],
               brandId: 0,
               brandName: item.items[2],
               menuItemName: item.items[3],
               description: item.items[4],
               originalImageUrl: item.items[5],
               blobImageName: item.items[6],
               useDefaultImage: item.items[7],
            }
            temparr.push(tempfields);
         })

         state.menuItems = temparr;

         //map unique filters
         filterMenuItemCategories(state);

      },
      setMatchingDayParts: (state, { payload }) => {
         //payload = daypart options from db             
         state.daypartsFiltered.map((item) => {
            const daypartNameMatch = payload.find(daypart => daypart.dayPartName === item.dayPartName);

            if (daypartNameMatch !== undefined) {
               item.mappedCSVName = daypartNameMatch.dayPartName;
               // item.id = daypartNameMatch.id 
            }
         })
      },
      setMatchingMealParts: (state, { payload }) => {
         //payload = mealpart options from db        
         state.mealpartsFiltered.map((item) => {
            const mealpartNameMatch = payload.find(mealpart => mealpart.mealPartName === item.mealPartName);

            if (mealpartNameMatch !== undefined) {
               item.mappedCSVName = mealpartNameMatch.mealPartName;
               //   item.id = mealpartNameMatch.id 

            }
         })
      },
      setMatchingBrands: (state, { payload }) => {
         //payload = brand options from db        
         state.brandsFiltered.map((item) => {
            const brandNameMatch = payload.find(brand => brand.brandName === item.brandName);

            if (brandNameMatch !== undefined) {
               item.mappedCSVName = brandNameMatch.brandName;
            }
         })
      },
      updateDayPart: (state, { payload }) => {

         state.daypartsFiltered.map((item) => {
            if (item.id === payload.id) {
               item.mappedCSVName = payload.mappedCSVName

               //update all menu item names
               const tempArr = state.menuItems.map((menuitem) => {
                  if (menuitem.dayPartName === item.dayPartName) {
                     return {
                        ...menuitem,
                        dayPartName: item.mappedCSVName,
                     }
                  }
                  return menuitem;
               });

               state.menuItems = tempArr

            }
         })
      },
      updateMealPart: (state, { payload }) => {
         state.mealpartsFiltered.map((item) => {
            if (item.id === payload.id) {
               item.mappedCSVName = payload.mappedCSVName

               //update all menu item names
               const tempArr = state.menuItems.map((menuitem) => {
                  if (menuitem.mealPartName === item.mealPartName) {
                     return {
                        ...menuitem,
                        mealPartName: item.mappedCSVName,
                     }
                  }
                  return menuitem;
               });

               state.menuItems = tempArr
            }
         })
      },
      updateBrand: (state, { payload }) => {
         state.brandsFiltered.map((item) => {
            if (item.id === payload.id) {
               item.mappedCSVName = payload.mappedCSVName

               //update all menu item names
               const tempArr = state.menuItems.map((menuitem) => {
                  if (menuitem.brandName === item.brandName) {
                     return {
                        ...menuitem,
                        brandName: item.mappedCSVName,
                     }
                  }
                  return menuitem;
               });

               state.menuItems = tempArr
            }
         })
      },
      setDayPartIds: (state, { payload }) => {
         payload.map((daypart) => {
            state.menuItems.map((item) => {
               if (item.dayPartName === daypart.dayPartName) {
                  item.dayPartId = daypart.id
               }
            })
         })
      },
      setMealPartIds: (state, { payload }) => {
         payload.map((mealpart) => {
            state.menuItems.map((item) => {
               if (item.mealPartName === mealpart.mealPartName) {
                  item.mealPartId = mealpart.id
               }
            })
         })
      },
      setBrandIds: (state, { payload }) => {
         payload.map((brand) => {
            state.menuItems.map((item) => {
               if (item.brandName === brand.brandName) {
                  item.brandId = brand.id
               }
            })
         })
      },
      setAdminUser: (state, {payload}) => {
         state.adminUser = payload 
     },
      prepMenuItemsForReview: (state, { payload }) => {

         //strip out rows not to include import ('--------- Select ---------' in day part, meal part, or brand)  
         const daypartNotSelected = state.daypartsFiltered.filter(dp => dp.mappedCSVName === '--------- Select ---------')
         const mealpartNotSelected = state.mealpartsFiltered.filter(mp => mp.mappedCSVName === '--------- Select ---------')
         const brandNotSelected = state.brandsFiltered.filter(b => b.mappedCSVName === '--------- Select ---------')

         let items = state.menuItems;

         if (daypartNotSelected !== undefined) {
            const arr = items.filter((item) => daypartNotSelected.every((key) => item.dayPartName !== key.dayPartName));
            state.menuItems = arr
            items = arr
         }

         if (mealpartNotSelected !== undefined) {
            const arr = items.filter((item) => mealpartNotSelected.every((key) => item.mealPartName !== key.mealPartName));
            state.menuItems = arr
            items = arr
          
         }

         if (brandNotSelected !== undefined) {
            const arr = items.filter((item) => brandNotSelected.every((key) => item.brandName !== key.brandName));
            state.menuItems = arr
         }
     
            //form data for EF 
            const menuImports = [
               {
                  "adminUser": state.adminUser,
                  "cSVFileName": state.csvFileName,
                  "statusCode": "Processing",
                  "errorCount": 0,
                  "recordsCount": state.menuItems.length
               }
            ]
   
            let temparr = [];
            let tempfields = {};
   
            state.menuItems.map((item) => {
              
               tempfields = {
                  mealPart: item.mealPartId,
                  dayPart: item.dayPartId,
                  brand: item.brandId,
                  menuItemName: item.menuItemName,
                  description: item.description,
                  originalImageURL: item.originalImageUrl,  
                  blobImageName: item.blobImageName,
                  useDefaultImage: item.useDefaultImage      
               }
               temparr.push(tempfields);
            })
   
            state.reportForImport.push({
               "country": payload.selectedcountry.countryCode,
               "dateForRange": new Date(payload.reportDate).toISOString(),
               "menuImports": menuImports,
               "menuItems": temparr
            })

      },
      resetReport: () => initialState,
      extraReducers(builder) {
         builder
            .addCase(createMenuGroup.pending, (state, action) => {
               state.status = 'loading'
            })
            .addCase(createMenuGroup.fulfilled, (state, { payload }) => {
               state.status = 'succeeded' 
            })
            .addCase(createMenuGroup.rejected, (state, action) => {
               state.status = 'failed'
               state.error = action.error.message
            })

      }

   }
})

export const getHeaderRow = (state) => state.csvReport.headerRow;
export const getDbHeaders = (state) => state.csvReport.dbHeaders;
export const getMenuItems = (state) => state.csvReport.menuItems;
export const getReport = (state) => state.csvReport.report;
export const getDaypartsFiltered = (state) => state.csvReport.daypartsFiltered;
export const getMealpartsFiltered = (state) => state.csvReport.mealpartsFiltered;
export const getBrandsFiltered = (state) => state.csvReport.brandsFiltered;
export const getCSVFileName = (state) => state.csvReport.csvFileName;
export const getReportForImport = (state) => state.csvReport.reportForImport;
export const getCSVImportStatus = (state) => state.csvReport.status;
export const getCSVImportError = (state) => state.csvReport.error;


export const {
   addReport,
   mapDbHeaders,
   mapMenuItems,
   setMatchingDayParts,
   setMatchingMealParts,
   setMatchingBrands,
   updateDayPart,
   updateBrand,
   updateMealPart,
   resetReport,
   prepMenuItemsForReview,
   setDayPartIds,
   setMealPartIds,
   setBrandIds,
   importReport,
   setAdminUser,
} = csvReportSlice.actions


export default csvReportSlice.reducer

