
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
    menugroups: [],
    status: 'idle', //'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    selectedGroupId: 0,
}

export const fetchMenuItemsDefault = createAsyncThunk('menugroups/fetchMenuItemsDefault', async () => {
    try {
        const response = await axios.get('api/menugroups/group/US')        
        return [...response.data];        
    } catch (err) {
        console.log(err.message)
        return err.message;
    }
})

export const fetchImportPreview = createAsyncThunk('menugroups/fetchImportPreview', async (id) => {
    try {
        const apiUrl = 'api/menugroups/group/review/' + id
        const response = await axios.get(apiUrl)     
        return [...response.data];        
    } catch (err) {
        console.log(err.message)
        return err.message;
    }
})

const browseMenuItemsSlice = createSlice({
    name: "menugroups",
    initialState,
    reducers: {
        setList: (state, { payload }) => {
            state.menugroups = payload;
        },
        setSelectedGroupId: (state, { payload }) => {
            state.selectedGroupId = payload;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMenuItemsDefault.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchMenuItemsDefault.fulfilled, (state, { payload }) => {
                state.status = 'succeeded'
                state.menugroups = payload
               
            })
            .addCase(fetchMenuItemsDefault.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchImportPreview.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchImportPreview.fulfilled, (state, { payload }) => {
                state.status = 'succeeded'
                state.menugroups = payload
               
            })
            .addCase(fetchImportPreview.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const selectDefaultMenuGroups = (state) => state.menugroups.menugroups;
export const getMenuGroupStatus = (state) => state.menugroups.status;
export const getMenuGroupError = (state) => state.menugroups.error;
export const getMenuItemsForGroup = (state) => state.menugroups.menugroups.menuItems;


export const { setList, setSelectedGroupId } = browseMenuItemsSlice.actions

export default browseMenuItemsSlice.reducer