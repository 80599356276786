import React, { useEffect, useState } from "react"
import theme from "./theme"
import Splash from "./pages/Splash"
import ManageConceptLockerFilters from "./pages/admin/ManageConceptLockerFilters"
import CreateCulinaryConcepts from "./pages/admin/CreateCulinaryConcepts"
import EditCulinaryConcepts from "./pages/admin/EditCulinaryConcepts"
import ManageCulinaryConcepts from "./pages/admin/ManageCulinaryConcepts"
import DashboardLayout from "./Layouts/DashboardLayout"
import BrowseConcepts from "./pages/BrowseConcepts"
import Presentations from "./pages/Presentations"
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Route, Routes } from "react-router-dom"
//import Test from "./Test"
import SavedSearches from "./pages/SavedSearches"
import ScrollToTop from "./ScrollToTop"
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import { getUser, getUserStatus, getUserError, fetchAuthUser } from './store/userSlice'
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid'
import { HOSTNAME } from './components/shared/Constants'

export default function App() {
    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);

    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => { // Anything except 2XX goes to here
        const status = error.response?.status || 500;
        console.log("status:" + status)
        if (status === 401) {
            window.location =  "https://" + HOSTNAME + "/auth"
            setLoading = true;
            setLoggedIn = false;
        } else {
            return Promise.reject(error);
        }
    });

    const dispatch = useDispatch();
    const user = useSelector(getUser);
    const userStatus = useSelector(getUserStatus);
    const error = useSelector(getUserError);

    useEffect(() => {
        if (userStatus === 'idle') {
            dispatch(fetchAuthUser())                     
        }
    }, [userStatus, dispatch])


    const checkAuth = () => {
        console.log("userStatus !!" + userStatus)
        if (userStatus === 'suceeded') {
            setLoading(false);
            setLoggedIn(true);
        } else if (userStatus === 'failed') {
            setLoading(true);
            setLoggedIn(false);
            window.location = "https://" + HOSTNAME + "/auth"
        }
    }


    if (userStatus !== "succeeded") {
        
        return (
            <>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '100vh' }}>
                        <CircularProgress color="success" />
                    </Grid>
                </ThemeProvider>
            </>
        )

    }
  
    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ScrollToTop />
                <Routes>
                    <Route element={<DashboardLayout />}>
                    <Route path="/admin"  element={
                        user.isAdmin ? < ManageCulinaryConcepts />
                        : <Splash />
                       } />
                        
                        <Route path="/admin/manage-filters" element={
                        user.isAdmin ? <ManageConceptLockerFilters />
                        : <Splash />
                       } />

                        <Route path="/admin/create-concept" element={
                        user.isAdmin ? <CreateCulinaryConcepts />
                        : <Splash />
                       } />
                        
                        <Route path="/admin/edit-concept/:conceptId" element={
                        user.isAdmin ? <EditCulinaryConcepts />
                        : <Splash />
                       } />                      
                        <Route path="/concepts" element={<BrowseConcepts/>} />
                        <Route path="/presentations" element={<Presentations />} />
                        <Route path="/saved-searches" element={<SavedSearches />} />
                    </Route>
                    <Route path="/" element={<Splash />} />
                </Routes>
            </ThemeProvider>
        </>
    )
}